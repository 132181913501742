import React, { useEffect, useState } from 'react';
import api from '../utils/tokenUtils';
import Swal from 'sweetalert2';

const FullTest = () => {
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState({});
    const [loading, setLoading] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [results, setResults] = useState([]);  // Initialize as an array
    const [correctCount, setCorrectCount] = useState(0); // To store correct answer count
    const [incorrectCount, setIncorrectCount] = useState(0); // To store incorrect answer count
    const [percentage, setPercentage] = useState(0); // To store success percentage

    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        try {
            const response = await api.post('/get_questions', {
                "exam": "2",
                "type": "full_random_test",
                // "tag": 8,
                // "question_id": 18
            });
            setQuestions(response.data.question);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch questions', error);
            Swal.fire({
                title: 'Error',
                text: 'Failed to fetch questions',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    const handleAnswerChange = (questionId, answerKey) => {
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionId]: answerKey
        }));
    };

    const handleSubmit = async () => {
        try {
            const answerArray = Object.keys(answers).map(questionId => ({
                question_id: Number(questionId),  // Convert questionId to a number
                answer: answers[questionId]
            }));

            const response = await api.post('/check_answers', { answers: answerArray });
            console.log('Submission Results:', response.data);  // Log the response for debugging
            const resultData = response.data.data || [];

            setResults(resultData); // Set the results

            const correctAnswers = resultData.filter(result => result.correct).length;
            const incorrectAnswers = 40 - correctAnswers;
            const successPercentage = (correctAnswers / 40) * 100;

            // Update state with the result stats
            setCorrectCount(correctAnswers);
            setIncorrectCount(incorrectAnswers);
            setPercentage(successPercentage);

            setSubmitted(true);

            Swal.fire({
                title: 'Success',
                text: 'Your answers have been submitted successfully!',
                icon: 'success',
                confirmButtonText: 'OK'
            });
        } catch (error) {
            console.error('Failed to submit answers', error);
            Swal.fire({
                title: 'Error',
                text: 'Failed to submit answers',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    // Get whether the user answer is correct or incorrect for a given question
    const getAnswerFeedback = (questionId, answerKey) => {
        const result = Array.isArray(results) && results.find(r => r.question_id === questionId);
        if (!result || !result.user_answer) return '';

        let user_answer = result.user_answer.toLowerCase()+"_text";

        if (result.correct) {
            return user_answer === answerKey ? 'correct' : '';
        } else {
            if (user_answer === answerKey) return 'incorrect';
            if (result.correct_answer === answerKey) return 'correct';
        }
        return '';
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div className="bg-gray-900 text-gray-100 min-h-screen p-6">
            {questions.length === 0 ? (
                <p>No questions available.</p>
            ) : (
                <div>
                    {questions.map((question) => (
                        <div key={question.question_id} className="my-12 bg-slate-700 px-10 py-10 rounded-lg">
                            <p className="mb-4 font-medium">{question.text}</p>
                            {question.image && question.image !== 'NULL' && (
                                <img
                                    src={`https://egzamin-inf.pl/images/egzaminy/${question.image}`}
                                    alt="Question"
                                    className="w-auto h-auto rounded-lg max-w-full mb-5 max-h-80 object-cover"
                                />
                            )}
                            <div className="mb-6">
                                {Object.entries(question.answers).map(([key, answer], index) => {
                                    const feedback = getAnswerFeedback(question.question_id, key);

                                    return (
                                        <button
                                            key={index}
                                            type="button"
                                            title={feedback}
                                            onClick={() => handleAnswerChange(question.question_id, key)}
                                            className={`block w-full text-left py-2 px-4 mb-2 rounded-lg ${feedback === 'correct'
                                                    ? 'bg-[#015501] text-white'
                                                    : feedback === 'incorrect'
                                                        ? 'bg-[#820000] text-white'
                                                        : answers[question.question_id] === key
                                                            ? 'bg-blue-500 text-white'
                                                            : 'bg-gray-800 text-gray-200 hover:bg-gray-900'
                                                }`}
                                            disabled={submitted}
                                        >
                                            {answer}
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                    ))}
                    {submitted && (
                        <div className="my-8 bg-gray-800 p-6 rounded-lg">
                            <h3 className="text-xl font-semibold mb-4">Results:</h3>
                            <p>Total Correct Answers: {correctCount}</p>
                            <p>Total Incorrect Answers: {incorrectCount}</p>
                            <p>Success Percentage: {percentage.toFixed(2)}%</p>
                        </div>
                    )}
                    <button
                        onClick={handleSubmit}
                        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                        disabled={submitted}
                    >
                        Submit Answers
                    </button>
                </div>
            )}
        </div>
    );
};

export default FullTest;
