import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MDEditor from '@uiw/react-md-editor';
import api from '../utils/tokenUtils'; // Import the Axios instance

const GuideViewer = () => {
    const { id } = useParams(); // Get guide ID from the URL
    const [guide, setGuide] = useState(null); // State to hold guide details
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchGuide = async () => {
            try {
                const response = await api.post(`/guide/id/${id}`); // Fetch guide data from backend
                setGuide(response.data); // Set guide data to state
            } catch (err) {
                setError('Guide not found or an error occurred.');
            }
        };

        fetchGuide();
    }, [id]); // Run this effect when the component mounts or `id` changes

    if (error) {
        return <p className="text-red-500 text-center mt-4">{error}</p>; // Tailwind styles for error message
    }

    if (!guide) {
        return <p className="text-gray-500 text-center mt-4">Loading...</p>; // Tailwind styles for loading message
    }

    return (
        <div className="guide-viewer max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-8">
            <h2 className="text-3xl font-bold text-gray-800 mb-4">{guide.title}</h2>
            <div className="text-gray-600 mb-4">
                <p><strong>Created by :</strong> {guide.username}</p>
                <p><strong>Creation Date:</strong> {new Date(guide.creation_date).toLocaleDateString()}</p>
            </div>

            {/* Render the markdown content */}
            <div className="prose prose-lg max-w-none">
                <MDEditor.Markdown source={guide.text} />
            </div>
        </div>
    );
};

export default GuideViewer;
