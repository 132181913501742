import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MDEditor from '@uiw/react-md-editor'; // Direct import for the markdown editor
import api from '../utils/tokenUtils'; // Import the Axios instance

const CreateGuide = () => {
    const { id } = useParams(); // Extract the optional ID from the URL
    const [questionId, setQuestionId] = useState(id || ''); // Initialize state with URL ID if present
    const [title, setTitle] = useState('');
    const [markdownText, setMarkdownText] = useState(''); // State for markdown content
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();



    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setSuccessMessage('');

        const guideData = {
            question_id: questionId || null, // Use null if questionId is empty
            title,
            text: markdownText, // Send markdown content to the server
        };

        if (!title || !markdownText) {
            setError('Please fill in all the fields.');
            return;
        }

        try {
            const response = await api.post('/guide/add', guideData);

            if (response.status === 201) {
                setSuccessMessage('Guide created successfully!');
                setTimeout(() => {
                    navigate(`/guide/id/${response.data.guide_id}`);
                }, 2000);
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                setError(error.response.data.error);
            } else {
                setError('An error occurred while creating the guide.');
            }
        }
    };

    return (
        <div className="create-guide max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-8">
            <h2 className="text-3xl font-bold mb-6 text-center">Create a New Guide</h2>

            <form onSubmit={handleSubmit}>
                <div className="form-group mb-4">
                    <label htmlFor="questionId" className="block text-lg font-semibold text-gray-700 mb-2">
                        Question ID (Optional):
                    </label>
                    <input
                        type="text"
                        id="questionId"
                        value={questionId}
                        onChange={(e) => setQuestionId(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-500"
                    />
                </div>

                <div className="form-group mb-4">
                    <label htmlFor="title" className="block text-lg font-semibold text-gray-700 mb-2">
                        Title:
                    </label>
                    <input
                        type="text"
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-500"
                    />
                </div>

                <div className="form-group mb-6">
                    <label htmlFor="markdownText" className="block text-lg font-semibold text-gray-700 mb-2">
                        Content (Markdown Supported):
                    </label>
                    <div className="border border-gray-300 rounded-md p-2">
                        <MDEditor
                            value={markdownText}
                            onChange={setMarkdownText} // Update markdown text as user types
                            height={400}
                        />
                    </div>
                </div>

                {/* Error and Success Messages */}
                {error && <p className="text-red-500 mb-4">{error}</p>}
                {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}

                <button
                    type="submit"
                    className="w-full py-2 px-4 bg-indigo-600 text-white font-bold rounded-md hover:bg-indigo-500 transition duration-300"
                >
                    Create Guide
                </button>
            </form>
        </div>
    );
};

export default CreateGuide;
