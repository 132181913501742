// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Login from './components/Login';
import Register from './components/Register';
import Protected from './components/Protected';
import FullTest from './components/FullTest'; // Import FullTest
import MainPage from './components/MainPage';
import NotFoundPage from './components/NotFoundPage';
import CreateGuide from './components/CreateGuide'; // Import the CreateGuide component
import GuideViewer from './components/GuideViewer'; // Import GuideViewer component
import VerifyEmail from './components/VerifyEmail';
import Mapper from './components/Mapper';


function App() {
    return (
        <Router>
            <Header />
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/test" element={<Protected />} />
                <Route path="/full-test" element={<FullTest />} />
                <Route path="/create-guide/:id?" element={<CreateGuide />} />
                <Route path="/guide/id/:id" element={<GuideViewer />} /> {/* Route for GuideViewer */}
                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path='/mapper' element={<Mapper />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </Router>
    );
}

export default App;
