// src/components/VerifyEmail.js
import React from 'react';

const VerifyEmail = () => {
  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center p-6">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-bold mb-6">Verify Your Email</h2>
        <p className="mb-4">Thank you for registering! A verification link has been sent to your email. Please verify your email to complete the registration process.</p>
      </div>
    </div>
  );
};

export default VerifyEmail;
